<template>
  <section class="scroll d-flex">
    <aside class="image-welcome"></aside>
    <main class="cms-welcome d-flex flex-column justify-between w-100">
      <div class="container-main mx-auto min-width">
        <div
          class="
            cms-welcome-header
            header
            text-center text-gray-150
            font_helvetica_neue75_bold
          "
        >
          <div
            class="header-user pointer"
            @click="$router.push('/usuario/mi-perfil')"
          >
            <i class="fas fa-user-circle"></i>

            <p v-if="user.userData.company_name" id="name">
              {{ user.userData.company_name }}
            </p>
          </div>
          <h1
            class="
              title
              text-center
              my-2
              mb-1
              text-primary
              font_helvetica_neue75_bold
              w-100
            "
          >
            {{ $t('welcome2.title') }}
          </h1>
          <h4 class="font_helvetica_neue75_bold mb-1 font-s-h3 px-1-1">
            {{ $t('welcome2.subtitle') }}
          </h4>
        </div>
        <div class="cms-welcome-body text-center">
          <h3 class="subtitle font_helvetica_neue75_bold">
            {{ $t('welcome2.selectCompany') }}
          </h3>
          <div class="companies">
            <div
              class="company pointer transition"
              @click="(e) => setSelectCompanyMethod('faber')"
              :class="user.selectCompany === 'faber' ? 'activated' : ''"
            >
              <h1 class="company-name font_helvetica_neue75_bold">
                {{ $t('welcome2.companyNames.faber') }}
              </h1>
              <img
                class="company-logo"
                src="/images/decorations/fc.png"
                alt="Default"
              />
            </div>
            <div
              class="company pointer transition"
              @click="(e) => setSelectCompanyMethod('vikingo')"
              :class="user.selectCompany === 'vikingo' ? 'activated' : ''"
            >
              <h1 class="company-name font_helvetica_neue75_bold">
                {{ $t('welcome2.companyNames.vikingo') }}
              </h1>
              <img
                class="company-logo"
                src="/images/decorations/vk.png"
                alt="Default"
              />
            </div>
          </div>
        </div>
        <div class="btn-close text-center mt-3">
          <button
            class="btn btn-primary link-modal"
            modal="warning-delete-file"
            @click="logout"
          >
            {{ $t('welcome2.logoutButton') }}
          </button>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import useSelectCompany from '@/modules/user/composables/ui/useSelectCompany';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';
import gaFunction from '@/utils/ga/gaFunction';
// const user = JSON.parse(localStorage.getItem('x-user'));
export default {
  created() {
    this.setSelectCompany(this.company);
  },
  setup() {
    const { push } = useRouter();
    const store = useStore();

    const logout = () => {
      store.commit('auth/logout');
      push('/');
    };

    return {
      ...useSelectCompany(),
      user: computed(() => store.getters['user/getInfoUser']),
      ...store.state,
      push,
      logout,
    };
  },
  /*   props: {
    name: {
      default: '',
      type: String,
      required: true,
    },
  }, */
  methods: {
    
    setSelectCompanyMethod(company) {      
      this.setSelectCompany(company);
      if(company == 'faber'){
        gaFunction('event', 'clic', { 'event_category' : 'Home', 'event_label' : 'Opcion seleccionada: Faber'});
      }
      else{
        gaFunction('event', 'clic', { 'event_category' : 'Home', 'event_label' : 'Opcion seleccionada: Vikingo'});
      }  
      this.push({ name: 'dashboard-billing' });
      
    },
  },
};
localStorage.setItem('new-user', false);
</script>
